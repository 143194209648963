function Post(props) {
    return (
        <article>
            <header>
                <span className="date">April 24, 2017</span>
                <h2>{props.title}</h2>
            </header>
            { "image" in props && <div className="image fit"><img src={props.image} alt=""/></div>}
            <p>{props.content}</p>
        </article>
    )
}

function Feed(props) {
    return (
        <div id="main">
            <section className="posts">
                <Post title="Very Interesting Post" content="More interesting things" />
                <Post title="A Second Post" content="Blah Blah Blah" image="https://imgix.ranker.com/user_node_img/36/709264/original/cheddar-cheese-photo-u7" />
                <Post title="Very Interesting Post" content="More interesting things" />
                <Post title="Very Interesting Post" content="More interesting things" />
            </section>
        </div>
    );
}

export default Feed