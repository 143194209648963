import logo from './logo.svg';
import './App.css';
import Feed from './Feed';
import react from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'

function Nav(props) {
  return (
    <nav id="nav">
      <ul className="links">
        <li className="active"><a href="/#main">Home</a></li>
        <li><a href="/#main">Post</a></li>
      </ul>
    </nav>
  );
}

function Intro(props) {
  return (
    <div id="intro">
      <h1>Verbis</h1>
      <p>An open social media platform built on top of Cloudflare Pages and Workers</p>
      <ul className="actions">
        <li><a href="#main" className="button icon solo"><FontAwesomeIcon icon={faArrowDown} size="lg" /></a></li>
      </ul>
    </div>
  );
}

function Copyright(props) {
  return (
    <div id="copyright">
      <ul><li>Design inspired by <a href="https://html5up.net">HTML5 UP</a>, modified by Matthew Craig</li></ul>
    </div>
  );
}

class App extends react.Component {

  render() {
    return (
      <body className="">
        <div id="wrapper" className="fade-in">
          <Intro />
          <Nav />
          <Feed />
          <Copyright />
        </div>
      </body>
    );
  }
}

export default App;
